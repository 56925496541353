.page {
  font-size: calc(10px + 2vmin);
}

.section-wrapper {
  display: block;
  padding-top: 2vh;
}

.section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-header-wrapper {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

.section-header {
  display: block;
  margin: 2vh 0 3vh 0;
  padding: 1vh 3vw;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
}

.conference-tile {
  display: flex;
  flex-direction: column;
  margin: 1vh 0;
  border-radius: 8px;
  color: white;
  font-size: calc(10px + 1vmin);
}

.tile-border-data {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 0.5vh 0;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tile-border-data {
  cursor: pointer;
}

.conference-tile-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh 1vw;
  font-size: calc(10px + 2.5vmin);
  flex-grow: 1;
}

hr {
  margin: 0 !important;
}

.tile-price-info {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 70%;
  justify-content: space-between;
}

.tile-info {
  padding: 0.5vh 0;
}

.tile-info a {
  margin: 0.5vh 0;
  text-decoration: none;
  color: inherit;
}

.courses-section {
  padding-bottom: 2vh;
  width: 100vw;
}

.courses-row {
  display: grid;
  margin: 2vh 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  
  -webkit-transform:rotate(-15deg);
  -moz-transform:rotate(-15deg);
  -ms-transform:rotate(-15deg);
  -o-transform:rotate(-15deg);
  transform:rotate(-15deg);
}