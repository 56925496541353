.container-fluid {
    padding: 0 !important;
}

.page {
    display: block;
    font-size: calc(10px + 2vmin);
}

.bottom-section {
    background-color: #f6f6f6;
    padding: 1vh auto;
    margin-top: 4vh;
}

.radio-section
.bottom-section div {
    margin: auto;
}

.agreements-section-header {
    display: flex;
    margin: 1vh auto 3vh;
    font-weight: bold;
    font-size: 30px;
    padding-top: 2vh;
}

.label {
    margin: 3vh 0 1vh;
    font-size: 28px;
}

.row {
    justify-content: space-around;
}

select, input:not([type=radio], [type=checkbox]) {
    font-size: 28px;
    cursor: pointer;
    padding: 1vh 1vw;
    padding-left: 2vw;
    border: 2px solid #115e92;
    width: 100%;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h32v32H0z' fill='%23115e92'/><path d='M12 13l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 10px;
}

.placeType {
    padding-left: 60px;
    border-right: none;
    background-color: lightgray;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h32v32H0z' fill='%23115e92'/><path d='M12 13l5 5 5-5z'/></svg>");
    background-position-x: 5%;
}

select:focus,
select:hover,
input:focus,
input:hover {
    outline: none;
}

button {
    font-style: italic;
    font-size: 28px;
    font-family: Lato;
    color: white;
    border: none;
    padding: 1vh 4vw;
    margin: 1vh !important;
    background-color: #115e92;
}

button:disabled {
    background-color: #88aec8;
}

.label-header {
    font-weight: 500;
    font-size: 35px;
    font-family: 'Lato';
    margin: 5vh 0 1vh 0;
}

.bottom-label {
    font-style: italic;
    font-size:24px;
    font-family:Lato;
    margin-top: 2vh;
}


.radio-section label,
.agreements-section label {
    position: relative;
    text-align: left;
    padding-left: 40px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.agreements-section label {
    display: flex;
    text-align: left;
    font-size: 18px;
    margin: 1vh 0;
}

.radio-section input,
.agreements-section input {
    opacity: 0;
    height: 0;
    width: 0;
}

.radio-section span,
.agreements-section span {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: transparent;
    border: 2px solid #115e92;
}

.radio-section label:hover input ~ span,
.agreements-section label:hover input ~ span {
    background-color: #115e92;
}
  
.radio-section :checked ~ span,
.agreements-section :checked ~ span {
    background-color: #115e92;
}
  
span:after {
    content: "";
    position: absolute;
    display: none;
}
  
.radio-section :checked ~ span:after,
.agreements-section :checked ~ span:after {
    display: block;
}
  
.radio-section span:after,
.agreements-section span:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
  
.agreements-section span {
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
}

.agreements-section span:after {
    left: 6px;
    top: 2px;
    width: 5px;
    border-width: 0 2px 2px 0;
}

.button-section button {
    font-style: normal;
    margin: 3vh 0 2vh 0;
}

button:enabled {
    cursor: pointer;
}

.error {
    color: red;
    margin-top: 5px;
    font-size: 18px;
}

.agreement-error {
    color: red;
}

.MuiAutocomplete-root {
    margin: auto !important;
}

.MuiOutlinedInput-root,
.MuiAutocomplete-inputRoot {
    padding: 0 !important;
}

.MuiFormLabel-root,
.MuiAutocomplete-endAdornment {
    display: none !important;
}

input:has(+ div.error),
select:has(+ div.error) {
    border: 2px solid red;
}