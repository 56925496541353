* {
  font-family: 'Lato';
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 0 20px;
}

.App-header {
  background-color: white;
  height: 15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-second {
  background-color: #CEDFE9;
  padding: 1vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #115e92;
}

.App-header-third {
  background-color: #115e92;
  padding: 1vh;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: white;
}